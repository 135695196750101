<template>
  <div class="main">
    <a-row align="middle" justify="center" type="flex">
      <a-col :span="8">
        <a-page-header title="客服管理" sub-title="新增、编辑、删除 客服" />
      </a-col>
      <a-col :span="8" :offset="8" style="text-align: right;padding-right:8px">
        <a-button type="primary" icon="plus" @click="showAddModal">新 增</a-button>
      </a-col>
    </a-row>
    <!-- 菜单列表 -->
    <a-spin tip="Loading..." :spinning="listLoading">
      <a-table :columns="tableTitle" :data-source="data" :rowKey="record=>record.id" size="middle">
        <span slot="action" slot-scope="text, record">
          <a @click="showEditModal(record.id, record.nickname)">Edit</a>
          <a-divider type="vertical" />
          <a-popconfirm title="删除后无法恢复,确定?" @confirm="del(record.id)" okText="Yes" cancelText="No">
            <a>Del</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-spin>
    <!-- 菜单列表 End -->
    <!-- 新增model -->
    <a-modal v-model="addModel" title="新增管理员" @ok="postAddForm" @cancel="hideAddModal">
      <template slot="footer">
        <a-button key="back" @click="hideAddModal">取消</a-button>
        <a-button key="submit" type="primary" :loading="postLoading" @click="postAddForm">保存</a-button>
      </template>
      <a-spin tip="Loading..." :spinning="postLoading">
      <a-form-model ref="addForm" :rules="rules" :model="addForm" :label-col="{span: 4}" :wrapper-col="{span: 19}">
        <a-form-model-item label="账号" prop="nickname">
          <a-input v-model="addForm.nickname" placeholder="输入账号" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input-group compact>
            <a-input v-model="addForm.password" placeholder="输入密码" style="width: 80%"/>
            <a-button type="primary" style="width: 20%" @click="randPassWord">生成</a-button>
            </a-input-group>
        </a-form-model-item>
      </a-form-model>
      </a-spin>
    </a-modal>

    <!-- 编辑model -->
    <a-modal v-model="editModel" title="编辑菜单" @ok="postEditForm" @cancel="hideEditModal">
      <template slot="footer">
        <a-button key="back" @click="hideEditModal">取消</a-button>
        <a-button key="submit" type="primary" :loading="postLoading" @click="postEditForm">保存</a-button>
      </template>
      <a-spin tip="Loading..." :spinning="postLoading">
      <a-form-model ref="addForm" :rules="rules" :model="addForm" :label-col="{span: 4}" :wrapper-col="{span: 19}">
        <a-form-model-item label="账号" prop="nickname">
          <a-input v-model="addForm.nickname" placeholder="输入账号" />
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-group compact>
            <a-input v-model="addForm.password" placeholder="不修改密码请留空" style="width: 80%"/>
            <a-button type="primary" style="width: 20%" @click="randPassWord">生成</a-button>
            </a-input-group>
        </a-form-model-item>
      </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableTitle: [
        { title: "ID", dataIndex: "id", key: "id" },
        { title: "账号", dataIndex: "nickname", key: "nickname" },
        { title: "注册时间", dataIndex: "create_time", key: "create_time"},
        { title: "最后登录时间", dataIndex: "last_login_time", key: "last_login_time"},
        { title: "最后访问IP", dataIndex: "last_login_ip", key: "last_login_ip"},
        { title: "最后访问IP地址", dataIndex: "last_ip_owner", key: "last_ip_owner"},
        { title: "操作", key: "action", scopedSlots: { customRender: "action" }, width:"100px"}
      ],
      data: [],
      listLoading: false, //列表loadding
      //新增表单数据
      addForm: {nickname: "",password: ""},
      //表单数据 数据校检规则
      rules: {
        nickname: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      addModel: false, //新增model
      editModel: false, //编辑model
      postLoading: false //提交loadding
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    getListData() {
      this.listLoading = true;
      this.$http
        .get("/admin/admin/getSerList")
        .then(res => {
          //请求成功后的处理函数
          this.listLoading = false;
          this.data = res.data.result;
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    },
    showAddModal() {
      this.addModel = true;
    },
    hideAddModal() {
      this.$refs.addForm.resetFields();
      this.addModel = false;
    },
    showEditModal(id, nickname) {
        console.log(id, name)
      this.editModel = true;
      this.addForm = {id: id, nickname: nickname, password: ''}
    },
    hideEditModal() {
      this.$refs.addForm.resetFields();
      this.editModel = false;
    },
    postAddForm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.postLoading = true
          let data = new FormData();
          Object.keys(this.addForm).forEach(key => {
            data.append(key, this.addForm[key]);
          });
          this.$http
            .post("/admin/admin/addService", data)
            .then((res) => {
              //请求成功后的处理函数
              this.getListData(this.listPage);
              this.postLoading = false;
              this.hideAddModal();
              this.$notification["success"]({message: "系统提示",description: res.data.msg,duration: 2.5,key: 1});
            })
            .catch(() => {
              //请求失败后的处理函数
              this.postLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    postEditForm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.postLoading = true
          let data = new FormData();
          Object.keys(this.addForm).forEach(key => {
            data.append(key, this.addForm[key]);
          });
          this.$http
            .post("/admin/admin/editService", data)
            .then((res) => {
              //请求成功后的处理函数
              this.getListData(this.listPage);
              this.postLoading = false;
              this.hideEditModal();
              this.$notification["success"]({message: "系统提示",description: res.data.msg,duration: 2.5,key: 1});
            })
            .catch(() => {
              //请求失败后的处理函数
              this.postLoading = false;
            });
          
        } else {
          return false;
        }
      });
    },
    del(id){
      this.$http
        .get("/admin/admin/delService", {
          params: {
            //参数
            id: id
          }
        })
        .then(() => {
          this.getListData();
        })
    },
    randPassWord(){
        let pasArr = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','0','1','2','3','4','5','6','7','8','9','_','-','$','%','&','@','+','!'];
        let password = '';
        let pasArrLen = pasArr.length;
        for (let i=0; i<16; i++){
            let x = Math.floor(Math.random()*pasArrLen);
            password += pasArr[x];
        }
        this.addForm.password = password
    }
  }
};
</script>